<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Absence stagiaire</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" @click="close">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <label for="comment_text">Saisie d'un commentaire (facultatif):</label>
                <input
                  id="comment_text"
                  name="comment_text"
                  class="form-control"
                  type="text"
                  autoComplete="off"
                  v-model="content"
                />
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="close">
                  Fermer
                </button>
                <button type="button" class="btn btn-primary" @click="close">
                  Sauvegarder les modifications
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "pointage-modal",
  data() {
    return {
      content: "aaa",
      id: "",
    };
  },
  methods: {
    setText(id, content) {
      this.content = content;
      this.id = id;
    },
    close() {
      this.$emit("close", this.id, this.content);
    },
  },
};
</script>

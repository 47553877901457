import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://frate-planning.aelia-informatique.fr:28044/api/v1/pointage/";
// const API_URL = 'http://192.168.0.59/api/v1/pointage/';


// const API_URL = "http://192.168.0.99:8000/frate/api/v1/pointage/";

class PointageService {
  getParams() {
    return axios
      .get(API_URL + "getparams", {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getStagiaires(params) {
    return axios
      .post(
        API_URL + "getgroupepointage",
        {
          data: params,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  setPointage(datas) {
    return axios
      .post(
        API_URL + "setpointage",
        {
          data: datas,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  findStagiaires(params) {
    return axios
      .post(
        API_URL + "findstagiairesbycritere",
        {
          data: params,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  findStagiaire(params) {
    return axios
      .post(
        API_URL + "findstagiairebyid",
        {
          data: params,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  getPlanningFile() {
    return axios
      .get(
        API_URL + "fileplanning",
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  // externes
  getActions(params) {
    return axios
      .post(
        API_URL + "getactions",
        {
          data: params,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  setCommentaireAction(datas) {
    return axios
      .post(
        API_URL + "setcommentaireaction",
        {
          data: datas,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }
}

export default new PointageService();

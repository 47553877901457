<template>
  <div id="pointage">
    <pointage-modal
      v-show="isModalTextVisible"
      @close="closeModalText"
      ref="pointageModal"
    />
    <section class="modal-body ">
      <div class="flex-container-parent">
        <div class="flex-item-left-parent">
          <div class="flex-container">
            <div class="flex-item-left">
              <!-- <label for="monSelect"> -->
                Choisir une période&nbsp;
              <!-- </label> -->
            </div>
            <div class="flex-item-right">
              <select
                id="monSelectPeriode"
                class="form-control"
                @change="update()"
                v-model="selectedPeriode"
              >
                <option
                  v-for="item in periodes"
                  v-bind:key="item.IdPeriode"
                  v-bind:value="item.IdPeriode"
                  >{{ item.LibPeriode }}</option
                >
              </select>
            </div>
            <!-- <div class="flex-item-end"></div> -->
          </div>
          <div v-if="showFrateUser" class="flex-container">
            <div class="flex-item-left">
              <!-- <label for="monSelect"> -->
                Formateur remplaçant&nbsp;
              <!-- </label> -->
            </div>
            <div class="flex-item-right">
              <select
                id="monSelectFormateur"
                class="form-control"
                @change="update()"
                v-model="selectedFormateur"
              >
                <option v-bind:value="currentUser.username">Non remplacé</option>
                <option
                  v-for="item in formateursRemplacant"
                  v-bind:key="item.MatriculeRemplacant"
                  v-bind:value="item.MatriculeRemplacant"
                  >{{ item.NomFormateur }} {{ item.PrenomForm }}
                </option>
              </select>
            </div>
            <!-- <div class="flex-item-end"></div> -->
          </div>
          <div v-if="showExterneUser" class="flex-container">
            <div class="flex-item-left">
              <!-- <label for="monSelect"> -->
                Choisir une action&nbsp;
              <!-- </label> -->
            </div>
            <div class="flex-item-right">
              <select
                class="form-control"
                @change="update()"
                v-model="selectedAction"
              >
              <option>Choisir une action</option>
                <option
                  v-for="item in actions"
                  v-bind:key="item.IdGroupe"
                  v-bind:value="item.IdGroupe"
                  >{{ item.Libelle }}</option
                >
              </select>
            </div>
            <!-- <div class="flex-item-end"></div> -->
          </div>
          <div v-if="showFrateUser" class="flex-container">
            <div class="flex-item-left">
              Date
            </div>
            <div class="flex-item-right">
              <datepicker
                format="d MMMM yyyy"
                placeholder="Choisir une date"
                :language="fr"
                autocomplete="off"
                v-model="dateSelect"
                :typeable="false"
                @selected="update()"
                name="datepickerform"
              ></datepicker>
            </div>
            <!-- <div class="flex-item-end"></div> -->
          </div>
          <div class="flex-container" v-if="stagiaires.length">
            <div class="flex-item-left">
              Ajouter un stagiaire
            </div>
            <div class="flex-item-right">
              <Dropdown
                :options="options"
                v-on:selected="validateSelection"
                v-on:filter="getDropdownValues"
                :disabled="false"
                :name="'select-stagiaire-' + this.getRandomIntInclusive(1, 999999999999)"
                :maxItem="5"
                placeholder="Choix d'un stagiaire"
              >
              </Dropdown>
            </div>
            <div class="flex-item-end">
              <input
                @click="addStagiaire()"
                class="favorite styled"
                type="button"
                value="Ajouter"
              />
            </div>
          </div>
        </div>
        <div class="flex-item-right-parent">
          <textarea
                @change="setCommentaireAction()"
                class="form-control"
                name="action-comment"
                id="action-comment"
                type="text"
                v-model="commentaireAction"
                rows="6" cols="200"
              />
          
        </div>
      </div>
    </section>
    <section>
      <p class="title">{{ libelleAction }}</p>
      <span v-show="loading" class="spinner-border spinner-border-sm"></span>
      <span>{{ message }}</span>
    </section>
    <section v-if="stagiaires.length">
      <table class="flex-table" style="width: 100%;">
        <thead>
          <tr>
            <th>Stagiaire</th>
            <th>Présent (H)</th>
            <th>Absent (H)</th>
            <th>%</th>
            <th>H</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in stagiaires" v-bind:key="item.IdStagiaire">
            <td data-label="Stagiaire">
              <span
                ><strong>{{ item.Nom }}&nbsp;{{ item.Prenom }}</strong
                ><br />({{ item.NoContrat }})</span
              >
            </td>
            <td data-label="Présence/Absence">
              <input
                class="form-control"
                name="nb-heure-present"
                type="number"
                step="0.01"
                min="0"
                max="5"
                v-model="item.NbHeurePresent"
                style="width: 80px;"
                :disabled="item.PasDeModif == 1"
              />
            </td>
            <td>
              <input
                class="form-control"
                name="nb-heure-absent"
                type="number"
                step="0.01"
                min="0"
                max="5"
                v-model="item.NbHeureAbsence"
                style="width: 80px;"
                :disabled="item.PasDeModif == 1"
              />
            </td>
            <td>
              <input
                class="form-control"
                name="taux-realise"
                type="text"
                v-model="item.PrctRealise"
                style="width: 45px;"
                :disabled=true
              />
            </td>
            <td>
              <input
                class="form-control"
                name="heure-realise"
                type="text"
                v-model="item.NbHeureReal"
                style="width: 80px;"
                :disabled=true
              />
            </td>
            <td data-label="Etat">
              <select
                @change="updateEtatStagiaire"
                v-bind:id="item.NumInscription"
                v-bind:name="item.NumInscription"
                class="form-control"
                :disabled="item.PasDeModif == 1"
                v-model="item.IdTypeAbs"
              >
                <option value="null">Présent</option>
                <option value="1">Absence justifiée <span v-if="item.Commentaire">({{ item.Commentaire }})</span></option>
                <option value="2">Absence non justifiée <span v-if="item.Commentaire">({{ item.Commentaire }})</span></option>
              </select>
              </td> 
              <td>
              <a
                style="cursor: pointer;"
                title="Supprimer le stagiaire"
                class="favorite styled"
                @click="delStagaire(item.NumInscription)"
              >
                <font-awesome-icon icon="user-slash" />
              </a>
            </td>
            <td>
              <textarea
                  class="form-control"
                  name="stagiaire-comment"
                  type="text"
                  v-model="item.StagiaireComment"
                  style="width: 300px;"
                  rows="2" cols="40"
                  :disabled="item.PasDeModif == 1 ||item.IdTypeAbs"
                />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="centrer">
        <input
          name="boutonValider"
          type="submit"
          value="Valider le formulaire"
          @click="sendData()"
        />
      </div>
    </section>
  </div>
</template>

<script>
import PointageService from "../services/pointage.service";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import Dropdown from "vue-simple-search-dropdown";
import Options from "../models/options";
import PointageModal from "./pointageModal.vue";

export default {
  name: "pointage",
  components: {
    Datepicker,
    Dropdown,
    PointageModal,
  },
  data() {
    return {
      fr: fr,
      loading: false,
      message: "",
      periodes: [],
      stagiaires: [],
      stagiairesBackup: [],
      formateursRemplacant: [],
      selectedPeriode: [],
      selectedAction: [],
      selectedFormateur: [],
      dateSelect: moment.now(),
      groupePointage: "",
      options: [],
      selected: { name: null, id: null },
      idAction: null,
      libelleAction: "",
      codeGroupAppli: "",
      commentaireAction: "",
      // externes
      actions: [],
      isModalTextVisible: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showExterneUser() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_EXTERNE");
      }
      return false;
    },
    showFrateUser() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_FRATE");
      }
      return false;
    },
  },
  mounted() {
    this.dropdownName = 'select-stagiaire-' + this.getRandomIntInclusive(1, 999999999999);
    this.loading = true;
   
    PointageService.getParams().then(
      (response) => {
        response.periodes.forEach((e) => {
          if (e.LibPeriode === "Matin" && this.getMatinApremSoir() === 1)
            this.selectedPeriode = e.IdPeriode;
          else if (e.LibPeriode === "Après-Midi" && this.getMatinApremSoir() === 2)
            this.selectedPeriode = e.IdPeriode;
          else if (e.LibPeriode === "Soir" && this.getMatinApremSoir() === 3)
            this.selectedPeriode = e.IdPeriode;
        });
        this.periodes = response.periodes;
        this.formateursRemplacant = response.formateursRemplacant;
        this.selectedFormateur = this.currentUser.username;
        if (this.showExterneUser) {
          this.loadExterneParams();
        }
        this.loading = false;
        this.update();
      },
      (error) => {
        this.loading = false;
        this.message =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    openModalText(id, content) {
      this.$refs.pointageModal.setText(id, content);
      this.isModalTextVisible = true;
    },
    closeModalText(id, content) {
      console.log(id + " " + content);
      for (var i = 0; i < this.stagiaires.length; i++) {
        if (this.stagiaires[i].NumInscription === id) {
          this.stagiaires[i].Commentaire = content;
        }
      }

      // for (var i = 0; i < this.items.length; i++) {
      //   if (this.items[i].id === idt) {
      //     this.items[i].value2 = content;
      //   }
      // }
      // this.setItems();
      this.isModalTextVisible = false;
    },
    loadExterneParams() {
      // Externe USER
      if (this.showExterneUser) {
        this.loading = true;
        let params = new Object();
        params.formateurId = this.currentUser.username;
        params.date = this.customDateFormat(this.dateSelect);
        PointageService.getActions(params).then(
          (response) => {
            this.actions = response;
            // this.selectedAction = response[0].IdGroupe; // pour sélection la première action de la liste
            this.loading = false;
            // this.update(); // on actualise la page
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    delStagaire(idStagiaire) {
      var i = this.stagiaires.findIndex(
        (e) => e.NumInscription === idStagiaire
      );

      this.stagiaires.splice(i, 1);
      this.stagiairesBackup.splice(i, 1);
    },
    addStagiaire() {
      let params = new Object();
      params.periode = this.selectedPeriode;
      params.formateurId = this.selectedFormateur;
      params.date = this.customDateFormat(this.dateSelect);
      params.idGroupeStagiaire = this.selected.id;
      // this.loading = true;
      PointageService.findStagiaire(params).then(
        (e) => {
          if (e.NoContrat !== undefined) {
            let testPresenceStagiaire = false;
            this.stagiaires.forEach((stagiaire) => {
              if (stagiaire.NoContrat === e.NoContrat)
                testPresenceStagiaire = true;
            });
            if (!testPresenceStagiaire) {
              this.stagiaires.push(e);
              this.stagiairesBackup.push(JSON.parse(JSON.stringify(e)));
              alert("Stagiaire ajouté à la liste");
            } else {
              alert("Le stagiaire est déjà présent dans la liste");
            }
            this.selected = [];
            this.message = "";
          } else {
            alert("Pas de stagaire ajouté");
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );

      // console.log(this.selected.id + " has been selected");
    },
    setCommentaireAction() {
      // this.loading = true;
      let params = new Object();
      params.commentaireAction = this.commentaireAction;
      params.selectedFormateur = this.selectedFormateur;
      params.idAction = this.idAction;
      params.codeGroupAppli = this.codeGroupAppli;

      if (this.idAction) {
      PointageService.setCommentaireAction(params).then(
        (e) => {
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
      }
    },
    validateSelection(selection) {
      this.selected = selection;
    },
    getDropdownValues(keyword) {
      // this.loading = true;
      this.options = [];
      let params = new Object();
      params.periode = this.selectedPeriode;
      params.formateurId = this.selectedFormateur;
      params.date = this.customDateFormat(this.dateSelect);
      params.criteres = keyword;
      if (this.selectedAction) {
        params.actionId = this.selectedAction;
      }
      if (keyword.length > 0 && keyword.split(" ").length === 1) {
        PointageService.findStagiaires(params).then(
          (e) => {
            if (e) {
              e.forEach((el) => {
                let name = el.Nom + " " + el.Prenom + " " + el.CIR;
                this.options.push(new Options(el.IdGroupeStagiaire, name));
              });
              this.message = "";
            } else {
              this.message = "Pas de résultat";
            }
            this.loading = false;
          },
          (error) => {
            this.loading = false;
          }
        );
      }
    },
    customDateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    update() {
      this.loading = true;
      this.$nextTick(() => {
        this.getStagiaires();
      });
    },
    getStagiaires() {
      let params = new Object();
      params.periode = this.selectedPeriode;
      params.formateurId = this.selectedFormateur;
      params.date = this.customDateFormat(this.dateSelect);
      params.action = this.selectedAction;
      PointageService.getStagiaires(params).then(
        (e) => {
          if (e) {
            this.stagiaires = e.stagiaires;
            this.stagiairesBackup = JSON.parse(JSON.stringify(e.stagiaires));
            this.libelleAction = e.libelleAction;
            this.codeGroupAppli = e.codeGroupAppli;
            this.commentaireAction = e.commentaireAction;
            this.idAction = e.idAction;
            this.message = "";
          } else {
            this.message = "Pas de résultat";
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
    },
    updateEtatStagiaire: function({ type, target }) {
      // console.log("updateEtatStagiaire " + type + " : " + target.value + " " + target.id);
      for (var i = 0; i < this.stagiaires.length; i++) {
        if (this.stagiaires[i].NumInscription === target.id) {
          if (target.value === "null") {
            // present
            this.stagiaires[i].IdTypeAbs = null;
            this.stagiaires[i].NbHeurePresent = this.stagiairesBackup[
              i
            ].NbHeurePresent;
            this.stagiaires[i].NbHeureAbsence = 0;
          } else if (target.value === "1") {
            // absence justifiée
            this.stagiaires[i].IdTypeAbs = 1;
            this.stagiaires[i].NbHeurePresent = 0;
            this.stagiaires[i].NbHeureAbsence = this.stagiairesBackup[
              i
            ].NbHeurePresent;
            this.openModalText(target.id, this.stagiaires[i].Commentaire);
          } else if (target.value === "2") {
            // absence non justifiée
            this.stagiaires[i].IdTypeAbs = 2;
            this.stagiaires[i].NbHeurePresent = 0;
            this.stagiaires[i].NbHeureAbsence = this.stagiairesBackup[
              i
            ].NbHeurePresent;
            this.openModalText(target.id, this.stagiaires[i].Commentaire);
            this.stagiaire[i].StagiaireComment = this.stagiairesBackup[
              i
            ].StagiaireComment;
          }
        }
      }
    },
    sendData() {
      if (confirm("Voulez-vous vraiment valider ce formulaire ?")) {
        // this.$nextTick(() => {
        let params = new Object();
        params.periode = this.selectedPeriode;
        params.formateurId = this.selectedFormateur;
        params.date = this.customDateFormat(this.dateSelect);
        var count = 0;
        var countAModifier = 0;
        this.stagiaires.forEach((e) => {
          if (e.PasDeModif != 1) {
            countAModifier++;
          }
        });
        this.stagiaires.forEach((e) => {
          if (e.PasDeModif != 1) {
            params.stagiaire = e;
            this.loading = true;
            PointageService.setPointage(params).then(
              (e) => {
                count++;
                if (countAModifier === count) {
                  console.log(countAModifier + " " + count);
                  this.getStagiaires();
                }
                if (e) {
                  this.message = "";
                } else {
                  this.message = "Pas de résultat";
                }
                this.loading = false;
              },
              (error) => {
                this.loading = false;
              }
            );
          }
        });

        // });
      }
    },
    getMatinApremSoir() {
      var now = new Date();
      var heure = now.getHours();
      if (heure >= 1 && heure <= 11) return 1;
      // matin
      else if (heure >= 12 && heure <= 17) return 2;
      // aprem
      else if (heure >= 18 && heure <= 23) return 3;
      // soir
      else return 1;
    },
    getRandomIntInclusive: function(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    // Externes
    getActions() {
      let params = new Object();
      params.formateurId = this.selectedFormateur;
      params.date = this.customDateFormat(this.dateSelect);
      PointageService.getActions(params).then(
        (e) => {
          if (e) {
            this.actions = e.actions;
            this.message = "";
          } else {
            this.message = "Pas de résultat";
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style>
.styled {
  border: 0;
  line-height: 2.5;
  height: 40px;
  padding: 0 16px;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  border-radius: 10px;
  background-color: rgba(220, 0, 0, 1);
  background-image: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2) 30%,
    rgba(0, 0, 0, 0)
  );
  box-shadow: inset 2px 2px 3px rgba(255, 255, 255, 0.6),
    inset -2px -2px 3px rgba(0, 0, 0, 0.6);
}

.styled:hover {
  background-color: rgba(255, 0, 0, 1);
}

.styled:active {
  box-shadow: inset -2px -2px 3px rgba(255, 255, 255, 0.6),
    inset 2px 2px 3px rgba(0, 0, 0, 0.6);
}

.valider,
table,
.centrer {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
input[type="submit"] {
  cursor: pointer;
  background: #68b5c7;
  color: white;
  border-style: outset;
  border-color: #68b5c7;
  height: 50px;
  width: 200px;
  font: bold15px arial, sans-serif;
  text-shadow: none;
  display: inline-block;
}

.vdp-datepicker input {
  width: 250px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dropdown-input input {
  width: 250px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#pointage {
  padding-bottom: 20px;
}

#pointage .flex-container-parent {
  display: flex;
  /* flex-wrap: nowrap; */
}

#pointage .flex-item-left-parent {
  /* background-color: #f1f1f1; */
  margin: 2px;
  text-align: left;
  /* line-height: 40px; */
  width: 500px;
  /* font-size: 30px; */
}

#pointage .flex-item-right-parent {
  /* background-color: #f1f1f1; */
  margin: 2px;
  text-align: left;
  /* line-height: 40px; */
  /* width: 250px; */
  /* font-size: 30px; */
}

#pointage .flex-container {
  display: flex;
  flex-wrap: nowrap;
}

#pointage .flex-item-left {
  /* background-color: #f1f1f1; */
  margin: 2px;
  text-align: left;
  /* line-height: 40px; */
  width: 200px;
  /* font-size: 30px; */
}

#pointage .flex-item-right {
  /* background-color: #f1f1f1; */
  margin: 2px;
  text-align: left;
  /* line-height: 40px; */
  width: 250px;
  /* font-size: 30px; */
}

#pointage .flex-item-end {
  /* background-color: #f1f1f1; */
  margin: 2px;
  text-align: left;
  /* line-height: 40px; */
  flex: auto;
  /* font-size: 30px; */
}

@media (max-width: 620px) {
  #pointage .flex-container {
    flex-wrap: wrap;
  }
  #pointage .flex-item-right,
  #pointage .flex-item-left {
    flex: 100%;
  }
  #pointage .flex-item-left {
    display: none;
  }
}

/* #pointage {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#pointage td,
#pointage th {
  border: 1px solid #ddd;
  padding: 8px;
}

#pointage tr:nth-child(even) {
  background-color: #f2f2f2;
}

#pointage tr:hover {
  background-color: #ddd;
}

#pointage th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #68b5c7;
  color: white;
} */

/*** FLEX TABLE */
#pointage .flex-table {
  /* display: flex; */
  flex-direction: column;
  border: 1px solid #ccc;
  background: #fff;
}
#pointage .flex-table thead {
  color: #fff;
  background: #68b5c7;
}

#pointage .flex-table tbody tr + tr {
  border-top: 1px solid #ccc;
}
#pointage .flex-table thead tr th,
#pointage .flex-table tbody tr td {
  /* display: flex; */
  flex: 1;
  padding: 0.4em;
}
#pointage .flex-table tbody tr td .box1 {
  min-width: 160px;
}
#pointage .flex-table tbody tr td > span {
  width: 100%;
}

#pointage .flex-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#pointage .flex-table tbody tr:hover {
  background-color: #ddd;
}

@media screen and (max-width: 640px) {
  #pointage .flex-table {
    border: 0;
  }
  #pointage .flex-table thead {
    display: none;
  }
  #pointage .flex-table tbody tr {
    flex-direction: column;
    margin: 1em;
    border: 1px solid #ccc;
    align-items: center;
  }
  #pointage .flex-table tbody tr td {
    flex-direction: row;
  }
  #pointage .flex-table tbody tr td + td {
    border-top: 1px solid #ccc;
  }
  #pointage .flex-table tbody tr td:before {
    /* display: flex;
    align-items: center;
    margin: -0.5em -0.5em 0.75em -0.5em;
    padding: 0.5em;
    content: attr(data-label);
    color: #fff;
    background: #68b5c7; */
    display: none;
  }
}

.title {
  color: #212529;
  font-weight: bold;
  margin: auto;
  text-align: left;
  font-size: 1.4em;
  border-bottom: 1px solid #ccc;
}

/* Bootstrap 4 modal */
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
